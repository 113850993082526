import React from 'react';
import { Box, Stack } from '@mui/material';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { LinkList } from '../../link-list/link-list.component';
import { PROMOTED_LINKS } from '../../link-list/promoted-links';
import { ProductCategoryNavigation } from '../product-category-navigation/product-category-navigation.component';

export const SidebarWithCategoryNavigation = () => {
  return (
    <Stack direction="column" gap={1}>
      <Box padding={1.25}>
        <LinkList
          links={PROMOTED_LINKS}
          variant="primary"
          onLinkClick={(target) => sendMainNavigationEvent(parseEventTree(target.href))}
        />
      </Box>
      <Box padding={1.25}>
        <ProductCategoryNavigation />
      </Box>
    </Stack>
  );
};
